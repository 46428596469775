<template>
  <v-container fluid>
    <v-card>
      <template>
        <v-tabs background-color="primary" dark dense>
          <v-tab active-class="info">Warehouse Sublevels </v-tab>
          <v-tab active-class="info">Bin Location Attributes</v-tab>
          <v-tab-item>
            <v-card>
              <v-col cols="12">
                <v-data-table
                  mobile-breakpoint="md"
                  v-model="record.SubLevels"
                  dense
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  :headers="SubLevelsHeaders"
                  :items="SubLevels"
                  :search="search"
                  show-select
                >
                  <template v-slot:item.DispName="{ item }">
                    <v-text-field
                      outlined
                      dense
                      v-model="item.DispName"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.Activated="{ item }">
                    <v-checkbox
                      v-model="item.Activated"
                      :value="item.Activated"
                      :disabled="item.id == 1"
                    ></v-checkbox>
                  </template>
                </v-data-table>
              </v-col>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              mobile-breakpoint="md"
              v-model="record.attributes"
              :headers="AttrHeaders"
              :items="attributes"
              show-select
              dense
            >
              <template v-slot:item.DispName="{ item }">
                <v-text-field
                  outlined
                  dense
                  v-model="item.DispName"
                ></v-text-field>
              </template>

              <template v-slot:item.Activated="{ item }">
                <v-checkbox
                  v-model="item.Activated"
                  :value="item.Activated"
                ></v-checkbox>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </template>
      <v-row dense>
        <v-col cols="12">
          <v-btn color="accent" @click="sendData" type="submit">
            <v-icon left>mdi-content-trash</v-icon>Save
          </v-btn>
        </v-col>
      </v-row>
      <!-- snackbar -->
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      record: {},
      AttrHeaders: [
        { text: "Warehouse Sublevels", value: "KeyName" },
        { text: "Name", value: "DispName" },
        { text: "Active", value: "Activated" },
      ],
      SubLevelsHeaders: [
        { text: "Attribute", value: "KeyName" },
        { text: "Name", value: "DispName" },
        { text: "Active", value: "Activated" },
      ],
      attributes: [],
      SubLevels: [],
      search: "",
      loading: false,
      FieldsData: {},
    };
  },
  methods: {
    getFieldsData() {
      const self = this;
      this.$store
        .dispatch("get", `/bin-locations/fields`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.SubLevels = res.ResponseData.filter(
              (data) => data.FldType === "S"
            );
            self.attributes = res.ResponseData.filter(
              (data) => data.FldType === "A"
            );
          }
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
        });
    },
    sendData() {
      const data = {
        SubLevels: this.SubLevels,
        attributes: this.attributes,
      };
    
      const url = "/bin-locations/fields/create";
      this.$store.commit("loader", true);
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode === 1200) {
            this.$store.commit("loader", false);
            this.$refs.snackbar.show(res.ResultDesc, "green");
            this.$router.push("/inventory/bin-locations/field-activation");
          } else {
            this.$store.commit("loader", false);
            this.$refs.snackbar.show(res.ResultDesc, "red");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          console.error(err);
          this.$refs.snackbar.show(err.response.data.errors, "red");
          this.loading = false;
        });
    },
  },
  created() {
    this.getFieldsData();
  },
};
</script>

<style>
</style>